// components/BookCallButton.js

import React from 'react';


const BookCallButton = () => {
  return (
    <a
      href="https://wnojbda1xtg.typeform.com/to/KSD6cA2Z"
      target="_blank"
      rel="noopener noreferrer"
      className="book-call-button"
    >
      Book a Call
    </a>
  );
};

export default BookCallButton;
