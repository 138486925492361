import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import About from './components/About';
import Research from './components/Research';
import Products from './components/Products';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import './styles.css';

function App() {
  return (
    <Router>
      <div className="page-container">
        <Navbar />
        <main className="main-content">
          <Routes>
            {/* Redirect root path (/) to /about */}
            <Route path="/" element={<Navigate to="/about" replace />} />
            <Route path="/about" element={<About />} />
            <Route path="/research" element={<Research />} />
            <Route path="/products" element={<Products />} />
            {/* Redirect any invalid paths to /about */}
            <Route path="*" element={<Navigate to="/about" replace />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
