// src/About.js
import React from 'react';
import Typewriter from './Typewriter';

function About() {
  return (
    <div className="about-page">
      <h1>
        <Typewriter text="About Us" speed={60} />
      </h1>
      <section className="description-section">
        We are building AI systems that prioritize trust and robustness. Understand, debug, and steer AI with confidence using cutting-edge interpretability and advanced prompting techniques.
      </section>
    </div>
  );
}

export default About;
