import React from 'react';

function Footer() {
  return (
    <footer className="footer">
      <div className="container">
      <a href="https://www.linkedin.com/company/tangentic" target="_blank" rel="noopener noreferrer">
          <img 
            src={`${process.env.PUBLIC_URL}/LinkedIn.svg`} 
            alt="LinkedIn" 
            className="linkedin-icon" 
          /></a>
        Tangentic Inc. 2025. All Rights Reserved.
      </div>
    </footer>
  );
}

export default Footer;
