import React, { useState, useEffect, useRef } from 'react';

const Typewriter = ({ text = '', speed = 50 }) => {
  const [displayedText, setDisplayedText] = useState('');
  const indexRef = useRef(0);
  const timeoutRef = useRef(null);

  useEffect(() => {
    // Reset displayed text and index when text changes
    setDisplayedText('');
    indexRef.current = 0;

    const typeCharacter = () => {
      // Avoid accessing text beyond its length
      if (indexRef.current < text.length) {
        const nextChar = text.charAt(indexRef.current);
        setDisplayedText((prev) => prev + nextChar);
        indexRef.current += 1;
        timeoutRef.current = setTimeout(typeCharacter, speed);
      } else {
        // Clear timeout when typing is complete
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
    };

    typeCharacter(); // Start typing effect

    return () => {
      // Clear any pending timeouts on cleanup
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [text, speed]);

  return <span>{displayedText}</span>;
};

export default Typewriter;
